<template>
    <div class="sort-operate-container">

        <div class="header">
            <div style="width: 205px"><logotop></logotop></div>
            <div class="header-right">
                <a-button class="back-btn bgBlue" size="large" type="primary" @click="$router.back()">
                    <a-icon type="arrow-left" /> 返回
                </a-button>
                <div class="header-title">{{datadetail.client}}</div>
                <div class="status" :style="{color:datadetail.sorting_status==2?'#34BC00':datadetail.sorting_status==1?'#BC7B00':'#BC1D00'}" >
                    <div :style="{background:datadetail.sorting_status==2?'#34BC00':datadetail.sorting_status==1?'#BC7B00':'#BC1D00'}"/>
                   {{datadetail.sorting_status_name}}
                </div>
            </div>
        </div>

        <div class="content-view">
            <div class="content-left">
                <div class="tab-header">
                    <div
                            v-for="(item, index) in tabItems"
                            :class="[{'tab-active': tabIndex === index}, 'tab-item']"
                            @click="tabChange(index)"
                    >
                        {{item}}
                    </div>

                </div>
                <div class="tab-content">
                    <SortPrint type="single" :loading="btnsortingloading" ref="sortPrint" v-if="tabIndex === 0" :data="datadetail" @changesort="sortingDataDo"/>
                    <MultiTag type="more_tip" :loading="btnsortingloading" v-if="tabIndex === 1" ref="multiTag" :data="datadetail" @changesort="sortingDataDo"/>
                    <ManyDistribute :loading="btnsortingloading" type="un_constant" ref="manyDistribute" v-if="tabIndex === 2" :data="datadetail" @changesort="sortingDataDo"/>
                    <SortCustom :loading="btnsortingloading" type="constant" ref="sortCustom" v-if="tabIndex === 3" :data="datadetail" @changesort="sortingDataDo"/>
                    
                   <btnsortingAction :data="datadetail" :type="tabIndex" @fresh="freshAll" @restAll="resetSorting"></btnsortingAction>
                </div>
            </div>
            <div class="keyboard-view">
                <a-row :gutter="[5,5]" style="background-color: transparent">
                    <a-col
                            :span="8"
                            v-for="(item,index) in keyboards" :key="index">
                        <a-button @click="keyboardClick(item)" class="keyboard-item" :class="{'btn-active': keyboardActive === item}">
                            <span v-if="item !== 'd'">{{item}}</span>
                            <img class="delete-icon" :src="keyboardActive === 'd' ? require('@/assets/delete_white.png') : require('@/assets/delete.png')" v-else>
<!--                            <img class="delete-icon" src="@/assets/delete_white.png" v-else>-->
                        </a-button>
                    </a-col>
                </a-row>
                <a-button class="complete-btn" :class="{'btn-active': keyboardActive === 'c'}" @click="keyboardClick('c')">
                    完成
                </a-button>
            </div>
        </div>


    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo
  import SortPrint from '@/components/sort_print'
  import MultiTag from '@/components/multi_tag'
  import ManyDistribute from '@/components/many_distribute'
  import SortCustom from '@/components/sort_custom'
  import { PrintAccount,CheckIsP } from '@/assets/js/doPrint'
  import btnsortingAction from '@/components/btnsortingAction'
  export default {
    name: "sort_operate",
    components: {
      logotop, SortPrint, MultiTag, ManyDistribute, SortCustom,btnsortingAction
    },
    data() {
      return {
        tabIndex: 0,
        tabItems: ['分拣打印', '多标签', '不定植分拣', '定值分拣'],
        keyboards: ['1','2','3','4','5','6','7','8','9','.','0','d'],
        keyboardActive: null,
       datadetail:{
           client:'',
           id:'',
           base_unit:'',
           sorting_status:'',
           sorting_status_name:'',
           order_sum:'',
           sorting_sum:'',
           stock_status:'',
           product_name:'',
           order_id:'',
       },
       printlist:[],
       sortingprint:true,//是否分拣打印标签
       btnsortingloading:false,
       sorting_tip_type:'',
       sorting_number_check:'',
       printsort:'sorting',
       username:'',
       oldUrl:'',
       printUrl:'',
      }
    },
    beforeRouteEnter(to,from,next){
      next(vm=>{
          vm.oldUrl=from.path
      })  
    },
    mounted() {
        this.$nextTick(()=>{
            console.log(this.oldUrl)
        })
       const user = localStorage.getItem('memberstore')
       if(user){
           this.username = JSON.parse(user).username
       }
     const sortingdatac = localStorage.getItem('sortingdatac')
     this.datadetail = JSON.parse(sortingdatac)
     this.sortingprint=this.datadetail.sortingprint;
     this.getclassfiy();
     this.getData();
     this.getRrintUrl();
    },
    methods: {
        getRrintUrl(e){
             this.loading=true;
            this.rq.get('/merapi/sorting/v2/print/get-print-url').then(res=>{
               for(let item of res.data){
                   if(item.key=='print_tag'){
                       this.printUrl=item.host+item.data[0].url;
                   }
               }
            }).catch(err=>{
              })
        },
        freshAll(){
            this.getData()
        },
        getclassfiy() {
          this.rq.get('/merapi/sorting/v2/common/code-list').then(res => {
            this.sorting_tip_type = res.data.config.sorting_tip_type
            this.sorting_number_check = res.data.config.sorting_number_check
          })
        },
        getData(){
            this.rq.get('/merapi/sorting/v2/sorting/get-sorting-order-info?sorting_order_id='+this.datadetail.id).then(res => {
              this.datadetail.sorting_status =res.data.sorting_status;
              this.datadetail.order_sum =res.data.order_sum;
              this.datadetail.sorting_sum =res.data.sorting_sum;
              this.datadetail.stock_status =res.data.stock_status;
              localStorage.setItem('sortingdatac', JSON.stringify(this.datadetail))
            })
        },
        // 实现分拣打印
        sortingDataDo(data){
            if(this.sortingprint&&!CheckIsP()){
                return;
            }
            var trueIf=true;
            this.printsort=data.type;
            var sortingtype=data.staticData.sorting_type;
            if((sortingtype=='single'||sortingtype=='more_tip')&&this.datadetail.sorting_status=='2'){
                trueIf=false;
            }
            if(this.sorting_tip_type=='2'&&this.sorting_number_check&&this.sorting_number_check!='0'&&trueIf){
                this.modelComfire(data.staticData)
            }else{
                this.reportcreateHttp(data.staticData);
            }
            
        },
        reportcreateHttp(staticData){
            this.btnsortingloading=true;
            this.rq.post('/merapi/sorting/v2/sorting/sorting',staticData).then(res=>{
                this.btnsortingloading=false;
                 var url=this.printUrl+'?'+res.data.url+'&type=tag'
                 var sortingtype=staticData.sorting_type;
                  
                if(this.printsort=='sorting'){//是打印还是分拣打印
                     
                     if((sortingtype=='single'||sortingtype=='more_tip')&&this.datadetail.sorting_status=='0'){
                         if(this.sorting_tip_type=='1'&&this.sorting_number_check&&this.sorting_number_check!='0'&&Number(res.data.sorting_sum)!=Number(this.datadetail.order_sum)){
                              this.suessFunModel(staticData,res.data.sorting_sum);
                         }else{
                             this.$message.success('分拣成功')
                             this.nextSortingFun();
                         }
                     }else{
                          this.$message.success('分拣成功')
                          this.nextSortingFun();
                     }
                     var data=this.datadetail;
                     if(this.sortingprint){
                        var length = res.data.printCount;
                         for(var i=0;i<length;i++){
                         	 PrintAccount('分拣标签打印',url,'print') 
                         }
                        
                     }
                }else{
                    var length = res.data.printCount;
                   for(var i=0;i<length;i++){
                   	 PrintAccount('分拣标签打印',url,'print') 
                   }
                }
                
                if(sortingtype=='un_constant'){
                    this.$refs.manyDistribute.requstReturn(staticData.sorting_number)
                }
                if(sortingtype=='constant'){
                    // this.$refs.sortCustom.requstReturn(staticData.sorting_number)
                }
                if(sortingtype=='single'){
                    this.$refs.sortPrint.requstReturn(staticData.sorting_number)
                }
                if(sortingtype=='more_tip'){
                    // this.$refs.multiTag.requstReturn(staticData.sorting_number)
                }
            }).catch(err=>{
                console.log(err)
                this.btnsortingloading=false;
              })
        },
        resetSorting(sortingtype){
            console.log('这是重置的时候')
            console.log(this.tabIndex)
            // if(this.tabIndex==0){
            //     this.$refs.sortPrint.requstReturn('')
            // }
        },
        nextSortingFun(){//执行分拣成功后操作
            this.getData();
            // this.datadetail.
             
        },
        // 分拣阀值判断
        modelComfire(Formdata){
            var that=this;
            var title='';
             var content='';
            var percent=this.sorting_number_check*0.01
            var dataMax=Number(this.datadetail.order_sum)+Number(this.datadetail.order_sum)*percent;
            var dataMin=Number(this.datadetail.order_sum)-Number(this.datadetail.order_sum)*percent;
            var sortingdata=Number(Formdata.sorting_number);
            if(Formdata.sorting_type=='un_constant'||Formdata.sorting_type=='constant'){
                sortingdata=Number(this.datadetail.sorting_sum)+Number(Formdata.sorting_number);
            }
            if(dataMax<=sortingdata){
                 var dataNumber=sortingdata-Number(this.datadetail.order_sum);
                 title='当前分拣数量将大于购买数量的'+this.sorting_number_check+'% (购买数量:'+this.datadetail.order_sum+',分拣数量将变为:'+sortingdata+',多分拣:'+dataNumber+'),确定就这样分拣!'
            }else if(dataMin>=sortingdata){
                if(Formdata.sorting_type=='single'||Formdata.sorting_type=='more_tip'){
                    var dataNumber=Number(this.datadetail.order_sum)-sortingdata;
                    title='当前分拣数量将小于购买数量的'+this.sorting_number_check+'%, (购买数量:'+this.datadetail.order_sum+',分拣数量将变为:'+sortingdata+',少分拣:'+dataNumber+'),确定就这样分拣!'
                    content='如果缺货请点击更多按钮下的部分缺货'
                }
            }
            if(title){
                this.$confirm({
                     title: title,
                     content:content,
                     okType: 'danger',
                     okText: '确定',
                     cancelText: '取消',
                     onOk:() => {
                       that.reportcreateHttp(Formdata);
                     },
                 });
            }else{
                that.reportcreateHttp(Formdata);
            }
        },
        suessFunModel(order,sortingnum){
            var percent=this.sorting_number_check*0.01
            var dataMax=Number(this.datadetail.order_sum)+Number(this.datadetail.order_sum)*percent;
            var dataMin=Number(this.datadetail.order_sum)-Number(this.datadetail.order_sum)*percent;
            var sortingdata=Number(sortingnum);
            console.log('区间:'+dataMin+'-'+dataMax)
            if(dataMax<=sortingdata){
                 this.sortingmoreOrderMFun(order,sortingdata);
            }else if(dataMin>=sortingdata){
                this.sortinglessOrderMFun(order,sortingdata);
            }
        },
        sortingmoreOrderMFun(order,sortingdata){
            var dataNumber=sortingdata-Number(this.datadetail.order_sum);
            var that=this;
            var title='当前分拣数量大于购买数量的'+this.sorting_number_check+'% (购买数量:'+this.datadetail.order_sum+',分拣数量:'+sortingdata+',多分拣:'+dataNumber+')';
            // 需要判断当前的缺货状态
            this.$warning({
                    title: title,
                    content:'',
                    okText: '分拣完成',
                    class:"sortingModelBox",
                    onOk:() => {
                        var data={
                            order_id:that.datadetail.order_id,
                            message:title,
                            admin_name:that.username,
                            sorting_order_id:order.sorting_order_id,
                            type:10
                        }
                        that.nextSortingFun();
                      that.updatasortinglog(data);
                    },
              }); 
        },
        sortinglessOrderMFun(order,sortingdata){
            var dataNumber=Number(this.datadetail.order_sum)-sortingdata;
            var that=this;
            var title= '当前分拣数量小于购买数量的'+this.sorting_number_check+'% (购买数量:'+this.datadetail.order_sum+',分拣数量:'+sortingdata+',少分拣:'+dataNumber+')';
            
            // 需要判断当前状态的缺货状态
            if(this.datadetail.stock_status==0){
                this.$warning({
                    title:title,
                    content:'',
                    okText: '分拣完成',
                    class:"sortingModelBox",
                    onOk:() => {
                       var data={
                             order_id:that.datadetail.order_id,
                             message:title,
                             admin_name:that.username,
                             sorting_order_id:order.sorting_order_id,
                             type:9
                         }
                       that.updatasortinglog(data);
                       that.nextSortingFun();
                    },
                  });
            }else{
                this.$confirm({
                    title:title,
                    content:'如果缺货请点击部分缺货',
                    okText: '部分缺货',
                    cancelText: '分拣完成',
                    class:"sortingModelBox",
                    onOk:() => {
                        that.onStockStatus(that.datadetail.id,0)
                        that.nextSortingFun();
                    },
                    onCancel:() =>  {
                          var data={
                                order_id:that.datadetail.order_id,
                                message:title,
                                admin_name:that.username,
                                sorting_order_id:order.sorting_order_id,
                                type:9
                            }
                          that.updatasortinglog(data);
                          that.nextSortingFun();
                      },
                  });
            }
        },
        onStockStatus(id,stock_status) { // 是否缺货操作
            if(id==''){
                 return;
             }
            var staticData={
                stock_status:stock_status
            }
            this.stocksortloading=true;
            this.rq.post('/merapi/sorting/v2/sorting/stock?id=' + id,staticData).then(res=>{
                this.freshAll();
                this.$message.success('编辑成功')
            }).catch(err=>{
              }) 
        },
        updatasortinglog(data){
             this.rq.post('/merapi/sorting/v2/sorting/sorting-ridge-log',data).then(res=>{
                 console.log('已上传确认日志')
             }).catch(err=>{
               }) 
        },
      tabChange(index) {
        this.tabIndex = index
      },
      keyboardClick(item) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null
        }
        this.keyboardActive = item;
        this.timeout = setTimeout(() => {
          this.keyboardActive = null
        }, 500);


        if (this.tabIndex === 0) {
          this.$refs.sortPrint.inputAction(item)
        } else if (this.tabIndex === 1) {
          this.$refs.multiTag.inputAction(item)
        } else if (this.tabIndex === 2) {
          this.$refs.manyDistribute.inputAction(item)
        } else if (this.tabIndex === 3) {
          this.$refs.sortCustom.inputAction(item)
        }
      },

    }
  }
</script>

<style scoped lang="less">
    .sort-operate-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        background: #F1F2F2;
        overflow: hidden;
        .header {
            display: flex;

            .header-right {
                background-color: #fff;
                flex: 1;
                display: flex;
                margin-left: 10px;
                align-items: center;
                .back-btn{
                    width:130px;
                    margin-left: 25px;
                }
                .bgBlue{
                    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                    height:80px;
                    outline:none;
                    border-radius: 5px; 
                    border:none;
                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                }
                .header-title {
                    flex: 1;
                    text-align: center;
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                }
                .status {
                    display: flex;
                    align-items: center;
                    font-size: 23px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    margin-right: 35px;
                    &>div {
                        width: 20px;
                        height: 20px;
                        border-radius: 100px;
                        margin-right: 12px;
                    }
                }
            }
        }
        .content-view {
            margin-top: 10px;
            display: flex;
            .content-left {
                width: 782px;
                .tab-header {
                    display: flex;
                    align-items: center;
                    .tab-item {
                        font-size: 23px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                        width: 160px;
                        height: 95px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .tab-active {
                        background: #FFFFFF;
                        position: relative;
                        &::after {
                            content: '';
                            width: 100%;
                            height: 6px;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                        }
                    }
                }
                .tab-content {
                    box-shadow: 4px 0px 40px 0px rgba(0, 0, 0, 0.13);
                     background: #fff;
                     padding-bottom: 30px;

                }
            }
            .keyboard-view {
                flex: 1;
                margin-left: 43px;
                margin-right: 34px;
                margin-top: 95px;
                .keyboard-item {
                    height: 85px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    font-size: 40px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #203EA1;
                    .delete-icon {
                        width: 39px;
                        height: 28px;
                    }

                }
                .complete-btn {
                    width: 401px;
                    height: 85px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #203EA1;

                }
                .btn-active  {
                    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                    color: #fff;
                }
            }
        }
    }
</style>