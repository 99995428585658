<template>
    <div class="sort-print-container">
        <div class="prod-info">
            <div class="info-item">
                <div class="item-title">商品名</div>
                <div class="item-value">{{dataObj.product_name}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">订购数量</div>
                <div class="item-value">{{dataObj.order_sum}}{{dataObj.base_unit}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">分拣数量</div>
                <div class="item-value"><span class="num">{{dataObj.sorting_sum}}</span>{{dataObj.base_unit}}</div>
            </div>
        </div>
        <div class="operate-view-item">
            <div class="input-tip">分拣数量：</div>
            <div class="input-view">
                <div class="input-container" :class="{'focus': currFocus === 1}">
                    <a-input autofocus @focus="sortNumFocus" v-model="sortNum" autoFocus size="large" class="input" :disabled="dataObj.sorting_status == '2'" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"/>
                </div>
                <div class="operate-btns" style="width:120px">
              
                    
                </div>
            </div>
        </div>

        <div class="operate-view-item" style="margin-top: 20px">
            <div class="input-tip">标签数量：</div>
            <div class="input-view">
                <div class="input-container" :class="{'focus': currFocus === 2}">
                     <a-input  @focus="signNumFocus" v-model="signNum" size="large" class="input"  onkeyup="value=this.value.replace(/\D+/g,'')"/>
                </div>
                <div class="operate-btns">
                     <a-button class="btn blue-btn" @click="sortAction('print')" v-if="dataObj.sorting_status === '2'" :loading="loading">打印</a-button>
                     <a-button class="btn blue-btn " @click="sortAction('sorting')" :loading="loading" v-else :disbale="dataObj.sorting_status == '2'">  分拣打印</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
       data:{},
       type:'',
       loading:false
    },
    data() {
      return {
        currFocus: 1,
        sortNum: '',
        signNum: '',
        dataObj:{},
      }
    },
    mounted(){
        this.dataObj = this.data;
        if(Number(this.dataObj.sorting_sum)!=0){
            this.sortNum=this.dataObj.sorting_sum
        }
    },
    watch:{
        data: function (a, b) {
          this.dataObj = a
          if(Number(this.dataObj.sorting_sum)!=0){
              this.sortNum=this.dataObj.sorting_sum
          }
        },
    },
    methods: {
      inputAction(item) {
           if(this.sortNum==null){
               this.sortNum=''
           }
           if(this.signNum==null){
               this.signNum=''
           }
          if (this.currFocus === 1) {
            if(this.dataObj.sorting_status == '2'){
                this.$message.warn('输入框被禁用')
                return;
            }
          }
        if (item === 'd') {
          if (this.currFocus === 1) {
            this.sortNum = this.sortNum.slice(0, this.sortNum.length - 1)
          } else if (this.currFocus === 2) {
              console.log('这是输入的额...')
            this.signNum = this.signNum.slice(0, this.signNum.length - 1)
          }
        } else if (item === 'c') {

        } else {
          if (this.currFocus === 1) {
            this.sortNum += item;
          } else if (this.currFocus === 2) {
              if(item=='.'){
                  return;
              }
              this.signNum += item;
           
          }
        }
        this.signNum=this.signNum.replace(/\D+/g,'')
        this.sortNum=this.sortNum.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')
      },
      sortAction(e) {
          var staticData={
            "sorting_constant": this.signNum,
            "sorting_number": this.sortNum,
            "sorting_order_id":  this.dataObj.id,
            "sorting_type": this.type
          }
          var data={
               staticData:staticData,
               type:e
           }
          this.$emit('changesort',data)
      },
      sortNumFocus() {
        this.currFocus = 1;
      },
      signNumFocus() {
        this.currFocus = 2;
      }
    }
  }
</script>

<style scoped lang="less">
    .sort-print-container {
        background-color: #fff;
        padding-bottom:40px;
        .prod-info {
            display: flex;
            align-items: center;
            padding-top: 40px;
            .info-item {
                flex: 1;
                text-align: center;
                .item-title {

                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 6px;
                }
                .item-value {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    .num {
                        color: #BC1D00;
                    }
                }
            }
        }
        .operate-view-item {
            margin-top: 30px;
            padding-left: 80px;
            padding-right: 80px;
            .input-tip {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 28px;
            }
            .input-view {
                display: flex;
                .input-container {
                    flex: 1;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    &.focus {
                        border: 1px solid #2D54DC !important;
                    }
                    .input {
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        font-size: 40px;
                        border: none;
                        font-family: HelveticaNeue-Bold, HelveticaNeue;
                        font-weight: bold;
                        color: #000000;
                        /deep/ .ant-input-number-input-wrap, /deep/ .ant-input-number-input {
                            height: 100% !important;
                            text-align: center;

                        }
                    }
                }
                .operate-btns {
                    margin-left: 32px;
                    display: flex;
                    .btn {
                        width: 120px;
                        height: 80px;
                        border-radius: 5px;
                        border: 1px solid #C6CBD4;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2343AE;
                        &.blue-btn {
                            color: #FFFFFF;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                        }
                        &.red-border-btn {
                            border: 1px solid #AC0F25;
                            background: #FFFFFF;
                            color: #A60B21;
                        }
                        &.red-btn {
                            color: #FFFFFF;
                            background: linear-gradient(133deg, #F5515F 0%, #9F041B 100%);
                        }
                    }
                    .btn+.btn {
                        margin-left: 10px;
                    }
                }
            }
        }

    }
</style>