<template>
    <div class="operate-view-item">
        <div class="input-view">
            <div style="flex: 1"></div>
            <div class="operate-btns">
                 <a-button class="btn" v-if="type=='1'" @click="gettaghistory">标签历史</a-button>
                 <!-- <a-button class="btn" v-if="type=='2'||type=='3'">分拣记录</a-button> -->
                <template  v-if="dataObj.sorting_status != 0">
                 <a-popconfirm title="确定是否重置当前分拣单?" cancel-text="取消" ok-text="确定" @confirm="() => resetSortAction(dataObj.id)">
                      <a-button class="btn" :loading="resetsortloading">分拣重置</a-button>
                 </a-popconfirm>
                 
                 </template>
                <template v-if="dataObj.sorting_status == 0">
                    <a-popconfirm v-if="dataObj.stock_status==0"  title="确定标记为有货?" ok-text="确定" cancel-text="取消" @confirm="() => signInStockAction(dataObj.id,1)">
                        <a-button  class="btn red-border-btn" :loading="stocksortloading">
                            标记有货
                        </a-button>
                    </a-popconfirm>
                    <a-popconfirm v-else title="确定标记为缺货?"  cancel-text="取消" ok-text="确定" @confirm="() => signInStockAction(dataObj.id,0)">
                        <a-button class="btn red-btn" :loading="stocksortloading">
                            标记缺货
                        </a-button>
                    </a-popconfirm>
                </template>
                
                <template v-if="dataObj.sorting_status == 2&&Number(dataObj.sorting_sum)<Number(dataObj.order_sum)">
                     <a-popconfirm v-if="dataObj.stock_status==0"  title="确定标记为有货?" cancel-text="取消" ok-text="确定" @confirm="() => signInStockAction(dataObj.id,1)">
                         <a-button  class="btn red-border-btn" :loading="stocksortloading">
                             标记有货
                         </a-button>
                     </a-popconfirm>
                     <a-popconfirm v-else title="确定标记为部分缺货?"  cancel-text="取消" ok-text="确定" @confirm="() => signInStockAction(dataObj.id,0)">
                         <a-button class="btn red-btn" :loading="stocksortloading">
                             部分缺货
                         </a-button>
                     </a-popconfirm>
                </template>
            </div>
        </div>
        
        <a-drawer
                :width="640"
                placement="right"
                :closable="false"
                :visible="showsortrecordtag"
                mask
                @close="showsortrecordtag=false"
                class="drawBoxcommon"
        >
           
            <div>
                <div class="title">
                    标签历史
                    <a-icon type="close-circle" class="close"  @click="showsortrecordtag=false"/>
                </div>
                <a-table :data-source="sortrecordtagList" :row-key="(record,index) => index" :loading="sortrecordtagLoading">
                    <a-table-column key="id" title="操作员" data-index="sortingAdmins.name"></a-table-column>
                    <a-table-column key="created_at" title="时间" data-index="created_at">
                        <template slot-scope="created_at">
                       <span>{{timeConvertData(created_at)}}</span>
                        </template>
                    </a-table-column>
                    <a-table-column key="is_used_name" title="分拣数量" data-index="sorting_number"></a-table-column>
                    <a-table-column key="more_tip_times" title="打印数" data-index="more_tip_times"></a-table-column>
                </a-table>
            </div>
        
        
        </a-drawer>
    </div>
</template>
<script>
    import { PrintAccount } from '@/assets/js/doPrint'
    import { timeConvert } from '@/assets/js/common'
  export default {

    props: {
       data:{},
       type:''
    },
    data() {
      return {
        value: '',
        dataObj:{},
        btnsortingloading:false,
        resetsortloading:false,
        sortingprint:false,
        stocksortloading:false,
        sortrecordtagList:[],
        showsortrecordtag:false,
        sortrecordtagLoading:false
    }
  },
  mounted(){
      this.dataObj = this.data;
      if(Number(this.dataObj.sorting_sum)!=0){
          this.value=this.dataObj.sorting_sum
      }
        
     
  },
  watch:{
      data: function (a, b) {
        this.dataObj = a
        if(Number(this.dataObj.sorting_sum)!=0){
            this.value=this.dataObj.sorting_sum
        }
      },
  },
    methods: {
        gettaghistory(){
            this.showsortrecordtag=true;
             this.getSortingRecord();
        },
        timeConvertData(e){
            return timeConvert(e,1)
        },
        getSortingRecord(){
            if(!this.data.id){
                return;
            }
            let staticData={
                sorting_order_id:this.data.id,
                type:2,
            }
            this.sortrecordtagLoading=true;
            this.rq.get('/merapi/sorting/v2/sorting/more-tip-list',{params: staticData}).then(res=>{
                this.sortrecordtagList=res.data
                this.sortrecordtagLoading=false;
            }).catch(err=>{
                this.sortrecordtagLoading=false;
              })
        },
      inputAction(item) {
        if (item === 'd') {
          this.value = this.value.slice(0, this.value.length - 1)
        } else if (item === 'c') {

        } else {
          this.value += item;
        }
      },
      signInStockAction(id,stock_status) {//标记缺货有货
          if(id==''){
              return;
          }
         var staticData={
             stock_status:stock_status
         }
         this.stocksortloading=true;
         this.rq.post('/merapi/sorting/v2/sorting/stock?id=' + id,staticData).then(res=>{
             this.stocksortloading=false;
             this.$message.success('编辑成功')
             this.$emit('fresh')
         }).catch(err=>{
             this.stocksortloading=false;
           }) 
      },
      resetSortAction(id = '') {
          var staticData = {
              'sorting_order_id': [id]
          };
          this.resetsortloading=true;
          this.rq.post('/merapi/sorting/v2/sorting/reset',staticData).then(res=>{
              this.resetsortloading=false;
              this.$message.success('重置成功')
              this.$emit('restAll',this.type)
              this.$emit('fresh')
              
          }).catch(err=>{
              this.resetsortloading=false;
            })
      },
      signOutStockAction() {
      }
    }
  }
</script>

<style scoped lang="less">
    .drawBoxcommon {
        .title{
            font-size: 23px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 32px;
            letter-spacing: 1px;
            position:relative;
            padding:30px 0px;
            .close{
                position:absolute;
                top:0px;
                right:0px;
                font-size: 36px;
            }
        }
        .tableheader{
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #919191;
            line-height: 28px;
            border-bottom:2px solid #F1F2F2;
            padding-bottom:20px;
        }
        .tablehbody{
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 28px;
            padding:10px 0px;
           
            .ant-col{
                 overflow:hidden;
               text-overflow:ellipsis;
               white-space:nowrap; 
            }
           
        }
    }
            .input-tip {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 28px;
            }
            .input-view {
                display: flex;
                padding-right:80px;
                .input-container {
                    flex: 1;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #2D54DC !important;
                    .input {
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        font-size: 40px;
                        font-family: HelveticaNeue-Bold, HelveticaNeue;
                        font-weight: bold;
                        border: none;
                        color: #000000;
                        /deep/ .ant-input-number-input-wrap, /deep/ .ant-input-number-input {
                            height: 100% !important;
                            text-align: center;

                        }
                    }
                }
                .operate-btns {
                    margin-left: 32px;
                    display: flex;
                    .btn {
                        width: 120px;
                        height: 80px;
                        border-radius: 5px;
                        border: 1px solid #C6CBD4;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2343AE;
                        &.blue-btn {
                            color: #FFFFFF;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                        }
                        &.red-border-btn {
                            border: 1px solid #AC0F25;
                            background: #FFFFFF;
                            color: #A60B21;
                        }
                        &.red-btn {
                            color: #FFFFFF;
                            background: linear-gradient(133deg, #F5515F 0%, #9F041B 100%);
                        }
                        &.disabled {
                            background: #DBDBDB;
                            color: #9A9A9A;
                        }
                    }
                    .btn+.btn {
                        margin-left: 10px;
                    }
                }
            }
</style>