<template>
    <div class="sort-print-container">
        <div class="prod-info">
            <div class="info-item">
                <div class="item-title">商品名</div>
                <div class="item-value">{{dataObj.product_name}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">订购数量</div>
                <div class="item-value">{{dataObj.order_sum}}{{dataObj.base_unit}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">分拣数量</div>
                <div class="item-value"><span class="num">{{dataObj.sorting_sum}}</span>{{dataObj.base_unit}}</div>
            </div>
        </div>
        <div class="operate-view-item">
            <div class="input-tip">分拣数量：</div>
            <div class="input-view">
                <div class="input-container">
                    <a-input v-model="value" autoFocus size="large" class="input" :disabled="dataObj.sorting_status == '2'" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"/>
                </div>
                <div class="operate-btns">
                     <a-button class="btn blue-btn" @click="sortAction('print')" v-if="dataObj.sorting_status === '2'" :loading="loading">再次打印</a-button>
                    <a-button class="btn blue-btn " @click="sortAction('sorting')" :loading="loading" v-else :disbale="dataObj.sorting_status == '2'">  分拣打印</a-button>
                  
                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    
  export default {
    props: {
       data:{},
       type:'',
       loading:false
    },
    data() {
      return {
        value: '',
        dataObj:{},
    }
  },
  mounted(){
      this.dataObj = this.data;
      this.btnloading = this.loading;
      if(Number(this.dataObj.sorting_sum)!=0){
          this.value=this.dataObj.sorting_sum
      }
  },
  watch:{
      data: function (a, b) {
        this.dataObj = a
        if(Number(this.dataObj.sorting_sum)!=0){
            this.value=this.dataObj.sorting_sum
        }
      },
  },
    methods: {
        requstReturn(e){
            console.log('这是返回时改变的')
            console.log(e)
            this.value=e;
        },
      inputAction(item) {
        if(this.dataObj.sorting_status == '2'){
            this.$message.warn('输入框被禁用')
            return;
        }
        if (item === 'd') {
          this.value = this.value.slice(0, this.value.length - 1)
        } else if (item === 'c') {

        } else {
           this.value += item
        }
      },
      sortAction(e) {
          var staticData={
            "sorting_constant": 1,
            "sorting_number": this.value,
            "sorting_order_id":  this.dataObj.id,
            "sorting_type": this.type
          }
           var data={
               staticData:staticData,
               type:e
           }
          this.$emit('changesort',data)
      },
    }
  }
</script>

<style scoped lang="less">
    .sort-print-container {
        background-color: #fff;
        padding-bottom: 40px;
        .prod-info {
            display: flex;
            align-items: center;
            padding-top: 40px;
            .info-item {
                flex: 1;
                text-align: center;
                .item-title {

                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 6px;
                }
                .item-value {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    .num {
                        color: #BC1D00;
                    }
                }
            }
        }
        .operate-view-item {
            margin-top: 40px;
            padding-left: 80px;
            padding-right: 80px;
            .input-tip {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 28px;
            }
            .input-view {
                display: flex;
                .input-container {
                    flex: 1;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    /*border: 1px solid #C6CBD4;*/
                    border: 1px solid #2D54DC !important;
                    .input {
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        font-size: 40px;
                        font-family: HelveticaNeue-Bold, HelveticaNeue;
                        font-weight: bold;
                        border: none;
                        color: #000000;
                        /deep/ .ant-input-number-input-wrap, /deep/ .ant-input-number-input {
                            height: 100% !important;
                            text-align: center;

                        }
                    }
                }
                .operate-btns {
                    margin-left: 32px;
                    display: flex;
                    .btn {
                        width: 120px;
                        height: 80px;
                        border-radius: 5px;
                        border: 1px solid #C6CBD4;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2343AE;
                        &.blue-btn {
                            color: #FFFFFF;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                        }
                        &.red-border-btn {
                            border: 1px solid #AC0F25;
                            background: #FFFFFF;
                            color: #A60B21;
                        }
                        &.red-btn {
                            color: #FFFFFF;
                            background: linear-gradient(133deg, #F5515F 0%, #9F041B 100%);
                        }
                        &.disabled {
                            background: #DBDBDB;
                            color: #9A9A9A;
                        }
                    }
                    .btn+.btn {
                        margin-left: 10px;
                    }
                }
            }
        }

    }
</style>