<template>
    <div class="sort-print-container">
        <div class="prod-info">
            <div class="info-item">
                <div class="item-title">商品名</div>
                <div class="item-value">{{dataObj.product_name}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">订购数量</div>
                <div class="item-value">{{dataObj.order_sum}}{{dataObj.base_unit}}</div>
            </div>
            <div class="info-item">
                <div class="item-title">分拣数量</div>
                <div class="item-value"><span class="num">{{dataObj.sorting_sum}}</span>{{dataObj.base_unit}}</div>
            </div>
        </div>
        <div class="operate-view-item"  style="margin-top: 6px">
            <div class="input-tip">每份分拣数量：</div>
            <div class="input-view">
                <div class="input-container" :class="{'focus': currFocus === 1}">
                    <a-input autofocus @focus="focusIndex(1)" v-model="num1" autoFocus size="large" class="input" onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"/>
                </div>
                <div class="operate-btns" style="width:120px">
                    
                   
                </div>
            </div>
        </div>

        <div class="operate-view-item" style="margin-top: 6px">
            <div class="input-tip">分拣次数：</div>
            <div class="input-view">
                <div class="input-container" :class="{'focus': currFocus === 2}">
                    <a-input @focus="focusIndex(2)" v-model="num2" size="large" class="input" onkeyup="value=this.value.replace(/\D+/g,'')"/>
                    
                </div>
               
                <div class="operate-btns">
                  <a-button class="btn blue-btn" @click="sortAction('sorting')" :loading="loading">分拣打印</a-button>
                </div>
            </div>
             <div class="input-tip" style="color:#9C9C9C">剩余量:{{sorting_residue}}</div>
        </div>
        <div class="bottom-tip">
            定值多次分拣：会根据定值一次分拣多份。分拣总数 = 定值 * 份数
        </div>
    </div>
</template>

<script>
  export default {
      components: {
      },
      props: {
         data:{},
         type:'',
         loading:false
      },
    data() {
      return {
        num1: '',
        num2: '',
        currFocus: 1,
        dataObj:{}
      }
    },
    mounted(){
        this.dataObj = this.data;
        if(Number(this.dataObj.sorting_sum)!=0){
            this.sortNum=this.dataObj.sorting_sum
        }
    },
    watch:{
        data: function (a, b) {
          this.dataObj = a
          if(Number(this.dataObj.sorting_sum)!=0){
              this.sortNum=this.dataObj.sorting_sum
          }
        },
    },
    computed: {
    	sorting_residue() {
            var sorting_residue=0;
            if(Number(this.dataObj.sorting_sum)==0){
            	sorting_residue =  Number(this.dataObj.order_sum)
            }else{
            	if( Number(this.dataObj.order_sum)-Number(this.dataObj.sorting_sum)>0){
            	sorting_residue =  Number(this.dataObj.order_sum)-Number(this.dataObj.sorting_sum)
            	}else{
            	sorting_residue =  0
            }
            }
    		return sorting_residue
    	}
    },
    methods: {
      inputAction(item) {
       
        if (item === 'd') {
          if (this.currFocus === 1) {
            this.num1 = this.num1.slice(0, this.num1.length - 1)
          } else if (this.currFocus === 2) {
            this.num2 = this.num2.slice(0, this.num2.length - 1)
          }
        } else if (item === 'c') {

        } else {
          if (this.currFocus === 1) {
            this.num1 += item;
          } else if (this.currFocus === 2) {
            this.num2 += item;
          }
        }
        this.num2=this.num2.replace(/\D+/g,'')
        this.num1=this.num1.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')
      },
      sortAction(e) {
          var staticData={
            "sorting_constant": this.num2,
            "sorting_number": this.num1,
            "sorting_order_id":  this.dataObj.id,
            "sorting_type": this.type
          }
         var data={
              staticData:staticData,
              type:e
          }
         this.$emit('changesort',data)
      },
      focusIndex(index) {
        this.currFocus = index;
      }
    }
  }
</script>

<style scoped lang="less">
    .sort-print-container {
        background-color: #fff;
        padding-bottom: 10px;

        .prod-info {
            display: flex;
            align-items: center;
            padding-top: 40px;

            .info-item {
                flex: 1;
                text-align: center;

                .item-title {

                    font-size: 20px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 6px;
                }

                .item-value {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;

                    .num {
                        color: #BC1D00;
                    }
                }
            }
        }

        .operate-view-item {
            margin-top: 20px;
            padding-left: 80px;
            padding-right: 80px;

            .input-tip {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                line-height: 28px;
            }

            .input-view {
                display: flex;

                .input-container {
                    flex: 1;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    &.focus {
                        border: 1px solid #2D54DC !important;
                    }
                    .input {
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        font-size: 40px;
                        border: none;
                        font-family: HelveticaNeue-Bold, HelveticaNeue;
                        font-weight: bold;
                        color: #000000;
                        /deep/ .ant-input-number-input-wrap, /deep/ .ant-input-number-input {
                            height: 100% !important;
                            text-align: center;

                        }
                    }
                }

                .operate-btns {
                    margin-left: 32px;
                    display: flex;

                    .btn {
                        width: 120px;
                        height: 80px;
                        border-radius: 5px;
                        border: 1px solid #C6CBD4;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2343AE;

                        &.blue-btn {
                            color: #FFFFFF;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                        }

                        &.red-border-btn {
                            border: 1px solid #AC0F25;
                            background: #FFFFFF;
                            color: #A60B21;
                        }

                        &.red-btn {
                            color: #FFFFFF;
                            background: linear-gradient(133deg, #F5515F 0%, #9F041B 100%);
                        }
                    }

                    .btn + .btn {
                        margin-left: 10px;
                    }
                }
            }
        }

        .bottom-tip {
            padding-left: 80px;
            padding-right: 80px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #9C9C9C;
            margin-top: 6px;
        }

    }
</style>